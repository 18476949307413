import React from "react"

import SEO from "../components/header/seo"
import Layout from "../theme/layout"
import CartDrawer from "../drawers/cart-drawer"

// ======================
//        COMPONENT
// ======================
const CartPage = ({ pageContext, location }) => {
  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO title="Cart" location={location} />
      <CartDrawer isCartPage location={location} />
    </Layout>
  )
}

export default CartPage
